import { combineReducers } from 'redux';
import { blogsReducer } from './blogs/reducer';
import { contactReducer } from './contact/reducer';
import { expertiseReducer } from './expertise/reducer';
import { homeReducer } from './home/reducer';
import { jobsReducer } from './jobs/reducer';
import { whatWeOfferReducer } from './whatWeOffer/reducer';

export const rootReducer = combineReducers({
    blogs: blogsReducer,
    contact: contactReducer,
    expertise: expertiseReducer,
    home: homeReducer,
    jobs: jobsReducer,
    whatWeOffer: whatWeOfferReducer,
});
