import { remove } from 'ramda';

export const getElementInArrayAt = <T>(index: number, array: T[]): [T, T[]] => {
    const indexToSplitAt = index === -1 ? array.length - 1 : index;
    const splitElement = array[indexToSplitAt];
    const restOfArray = remove(indexToSplitAt, 1, array);
    return [splitElement, restOfArray];
};

export const convertArrayToObject = (array, key): any => {
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item,
        };
    }, {});
};

export const isEmpty = <T>(array: T[]): boolean => array.length === 0;
export const isNotEmpty = <T>(array: T[]): boolean => !isEmpty(array);
