import { FETCH_WHATWEOFFER_SUCCESS, FETCH_WHATWEOFFER_ERROR } from './types';
import { ReduxDataState } from '../../types/redux';

const initialState = {
    data: null,
    error: null,
};

export const whatWeOfferReducer = (state = initialState, action): ReduxDataState => {
    switch (action.type) {
        case FETCH_WHATWEOFFER_SUCCESS:
            return Object.assign({}, state, { data: action.payload });
        case FETCH_WHATWEOFFER_ERROR:
            return Object.assign({}, state, { error: action.payload });
        default:
            return state;
    }
};
