import { convertArrayToObject } from 'utils/array';
import { FETCH_JOBS_SUCCESS, FETCH_JOBS_ERROR, FETCH_JOB_SUCCESS, FETCH_JOB_ERROR } from './types';
import { ReduxDataState } from 'types/redux';

const initialState = {
    data: {},
    error: null,
    loading: false,
    all: false,
    total: 0,
    count: 0,
};

export const jobsReducer = (state = initialState, action): ReduxDataState => {
    switch (action.type) {
        case FETCH_JOB_SUCCESS: {
            const newJob = action.payload;
            const newData = {
                ...state.data,
                [newJob.id]: newJob,
            };

            return Object.assign({}, state, {
                data: newData,
            });
        }
        case FETCH_JOB_ERROR: {
            return Object.assign({}, state, {
                error: action.payload,
            });
        }
        case FETCH_JOBS_SUCCESS: {
            const jobs = convertArrayToObject(action.payload.data, 'id');

            return Object.assign({}, state, {
                ...action.payload,
                data: jobs,
                all: action.payload.data.length === action.payload.total,
            });
        }
        case FETCH_JOBS_ERROR: {
            return Object.assign({}, state, {
                error: action.payload,
                all: false,
            });
        }
        default: {
            return state;
        }
    }
};
