import React from 'react';
import App, { AppProps } from 'next/app';
import withRedux, { MakeStore } from 'next-redux-wrapper';
import { PageLoadingBar } from '../components/page/PageLoadingBar';
import { Provider } from 'react-redux';
import { createStore, compose, Store } from 'redux';
import { initGA } from 'helpers/analytics';
import { rootReducer } from 'redux/rootReducer';
import 'bootstrap/dist/css/bootstrap.min.css';

let composeEnhancers = compose;

if (process.browser) {
    composeEnhancers = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;
}

/**
 * @param initialState The store's initial state (on the client side, the state of the server-side store is passed here)
 */
const makeStore: MakeStore = (initialState) => {
    return createStore(rootReducer, initialState, composeEnhancers());
};

interface MyAppProps extends AppProps {
    store: Store;
}
// TODO: Veranderen naar FC component
class MyApp extends App<MyAppProps> {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    static async getInitialProps({ Component, ctx }) {
        const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};

        return { pageProps };
    }

    componentDidMount(): void {
        initGA();
    }

    render(): JSX.Element {
        const { Component, pageProps, store } = this.props;

        return (
            <Provider store={store}>
                <PageLoadingBar />
                <Component {...pageProps} />
            </Provider>
        );
    }
}

export default withRedux(makeStore)(MyApp);
