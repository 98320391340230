import { convertArrayToObject } from 'utils/array';
import { FETCH_BLOGS_SUCCESS, FETCH_BLOGS_ERROR, FETCH_BLOG_SUCCESS, FETCH_BLOG_ERROR } from './types';
import { ReduxDataState } from 'types/redux';

const initialState = {
    data: {},
    error: null,
    loading: false,
};

export const blogsReducer = (state = initialState, action): ReduxDataState => {
    switch (action.type) {
        case FETCH_BLOG_SUCCESS: {
            const newBlogs = action.payload;
            const newData = {
                ...state.data,
                [newBlogs.id]: newBlogs,
            };

            return Object.assign({}, state, {
                data: newData,
                error: null,
            });
        }
        case FETCH_BLOG_ERROR: {
            return Object.assign({}, state, {
                error: action.payload,
            });
        }
        case FETCH_BLOGS_SUCCESS: {
            const blogs = convertArrayToObject(action.payload.data, 'id');

            return Object.assign({}, state, {
                ...action.payload,
                data: blogs,
                error: null,
            });
        }
        case FETCH_BLOGS_ERROR: {
            return Object.assign({}, state, {
                error: action.payload,
            });
        }
        default: {
            return state;
        }
    }
};
