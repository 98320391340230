import { FETCH_HOME_SUCCESS, FETCH_HOME_ERROR } from './types';
import { ReduxDataState } from '../../types/redux';

const initialState = {
    data: null,
    error: null,
};

export const homeReducer = (state = initialState, action): ReduxDataState => {
    switch (action.type) {
        case FETCH_HOME_SUCCESS:
            return Object.assign({}, state, { data: action.payload });
        case FETCH_HOME_ERROR:
            return Object.assign({}, state, { error: action.payload });
        default:
            return state;
    }
};
