import React from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';

import { red } from 'styles/colors';

const color = red;
const height = '3px';

export const PageLoadingBar: React.FC = () => {
    let timer: number = null;

    const routeChangeStart = (): void => {
        NProgress.set(0.3);
        NProgress.start();
    };

    const routeChangeEnd = (): void => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            NProgress.done(true);
        }, 200);
    };

    React.useEffect((): (() => void) => {
        Router.events.on('routeChangeStart', routeChangeStart);
        Router.events.on('routeChangeComplete', routeChangeEnd);
        Router.events.on('routeChangeError', routeChangeEnd);

        return (): void => {
            Router.events.off('routeChangeStart', routeChangeStart);
            Router.events.off('routeChangeComplete', routeChangeEnd);
            Router.events.off('routeChangeError', routeChangeEnd);
        };
    }, []);

    return (
        <style jsx global>
            {`
                #nprogress {
                    pointer-events: none;
                }
                #nprogress .bar {
                    background: ${color};
                    position: fixed;
                    z-index: 1031;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: ${height};
                }
                #nprogress .peg {
                    display: block;
                    position: absolute;
                    right: 0px;
                    width: 100px;
                    height: 100%;
                    box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
                    opacity: 1;
                    -webkit-transform: rotate(3deg) translate(0px, -4px);
                    -ms-transform: rotate(3deg) translate(0px, -4px);
                    transform: rotate(3deg) translate(0px, -4px);
                }

                #nprogress .spinner {
                    display: block;
                    position: fixed;
                    z-index: 1031;
                    top: 7.5px;
                    right: 7.5px;
                }

                #nprogress .spinner-icon {
                    width: 18px;
                    height: 18px;
                    box-sizing: border-box;
                    border: solid 2px transparent;
                    border-top-color: ${color};
                    border-left-color: ${color};
                    border-radius: 50%;
                    -webkit-animation: nprogresss-spinner 400ms linear infinite;
                    animation: nprogress-spinner 400ms linear infinite;
                }
                .nprogress-custom-parent {
                    overflow: hidden;
                    position: relative;
                }
                .nprogress-custom-parent #nprogress .spinner,
                .nprogress-custom-parent #nprogress .bar {
                    position: absolute;
                }
                @-webkit-keyframes nprogress-spinner {
                    0% {
                        -webkit-transform: rotate(0deg);
                    }
                    100% {
                        -webkit-transform: rotate(360deg);
                    }
                }
                @keyframes nprogress-spinner {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            `}
        </style>
    );
};
